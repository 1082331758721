import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`There are several date fields for a content item. Some can be seen in Studio in the `}<em parentName="p">{`System`}</em>{` tab under "Editing History" and the
library, others can only be viewed by using the server tool `}<inlineCode parentName="p">{`cm dump`}</inlineCode>{` on the content item.`}</p>
  <table>
    <tr>
      <th>Date Type</th>
      <th>Description</th>
      <th>Studio</th>
      <th>CM Dump</th>
    </tr>
    <tr>
      <td>Creation date</td>
      <td>The time the content item was created. This is never updated.</td>
      <td>X</td>
      <td>X
      </td>
    </tr>
    <tr>
      <td>Modification date</td>
      <td>The last time the content item was changed.</td>
      <td></td>
      <td>X
      </td>
    </tr>
    <tr>
      <td>Edition date</td>
      <td>The last time the content version was changed. One could also call this the version creation date. This date
        can be seen in Studio in the "Editing History" table for each version.</td>
      <td>X</td>
      <td>X
      </td>
    </tr>
    <tr>
      <td>Approved date</td>
      <td>The last time the content was approved. This is not explicitly saved, but this corresponds to an approval event
        as seen in the editing history (tooltip). The modification date is also updated.</td>
      <td>X</td>
      <td>
      </td>
    </tr>
    <tr>
      <td>Publication date</td>
      <td>Is updated whenever a version is published.</td>
      <td>X</td>
      <td>X
      </td>
    </tr>
    <tr>
      <td>Place approved date</td>
      <td>Is updated whenever the location of the content, which includes the name of the content or its parent, is changed
        and the content is then approved. The distinction between "place approved" and "approved" is important as the
        name changes do not change the version.</td>
      <td></td>
      <td>X
      </td>
    </tr>
  </table>
    <p>{`When only the name of the content is changed, the version is not changed, but the modification date is updated and the
content is no longer "place approved". Thus the content must be approved and published again, so that the "place approved"
date is updated. However, neither the publication date nor the approved date is changed as the version has not changed.`}</p>
    <p>{`The reason that the content needs to be republished after changing the name is that the CAE requires there to be a "placedependency"
event upon any change in the content location. This event triggers the invalidation of cache keys, etc. which depend on
the location of the content item. What exactly this event triggers is dependent on your CAE and cache key architecture.`}</p>
    <p>{`For more information about retreiving these dates per UAPI, see our JavaDoc for `}<a parentName="p" {...{
        "href": "https://documentation.coremedia.com/cmcc-12/artifacts/2406.0.4/javadoc/common/com/coremedia/cap/content/Content.html"
      }}>{`Content`}</a>{` and
`}<a parentName="p" {...{
        "href": "https://documentation.coremedia.com/cmcc-12/artifacts/2406.0.4/javadoc/common/com/coremedia/cap/content/Version.html"
      }}>{`Version`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      